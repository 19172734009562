import { render, staticRenderFns } from "./dataQuery.vue?vue&type=template&id=605d2dfa&scoped=true&"
import script from "./dataQuery.vue?vue&type=script&lang=js&"
export * from "./dataQuery.vue?vue&type=script&lang=js&"
import style0 from "./dataQuery.vue?vue&type=style&index=0&id=605d2dfa&prod&lang=scss&scoped=true&"
import style1 from "./dataQuery.vue?vue&type=style&index=1&id=605d2dfa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605d2dfa",
  null
  
)

export default component.exports